.container nav { padding-left: 0; padding-right: 0; }
div.highlighter-rouge { max-height: 100%; overflow: auto; margin-bottom: 1rem; border: 1px solid black;}
pre { padding: 4px; margin-top: 0px; margin-bottom: 0px; background-color: lightgoldenrodyellow; }
pre.highlight { padding: 4px; margin-top: 0px; margin-bottom: 0px; background-color: lightgoldenrodyellow; overflow: auto;}
code { font-family: 'Hack','Bitstream Vera Sans Mono','Courier', monospace;}
article.post { border-bottom: 1px solid grey; padding-bottom:10px; margin-bottom: 20px;}
.posts article.post h1 { font-size: 1.5em; }
a.outbound[href^="http://"]:not([href*="adrianmoreno.com"]):after,
a.outbound[href^="https://"]:not([href*="adrianmoreno.com"]):after{
    content: url('/assets/images/links/external.gif');
    padding-left: 2px;
    vertical-align: middle;
}
blockquote {
    border-left: silver solid 8px;
}
blockquote p {
    padding-left: 8px;
}
footer.container { padding-left: 0px; padding-right: 0px; }

@media print {
    .no-print, .no-print * { display: none !important; }
    .col-print-12 { max-width: 100%; flex: 0 0 100%; }
    pre.highlight { max-height: none; overflow: auto; background-color: white; }
    pre.highlight code span { color: black !important; }
    code.highlighter-rouge { background-color: lightgray; color: black;}
}