
//
// VARIABLES
//

// Colors
$blue: #4183C4;

// Grays
$black: #000;
$darkerGray: #222;
$darkGray: #333;
$gray: #666;
$lightGray: #eee;
$white: #fff;

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}